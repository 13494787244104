<template>
  <fm-modal title="维修申请" v-model="modal" :mask-closable="false" width="800" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <!-- <fm-title titleText="采购明细"></fm-title> -->
        <fm-form label-align="left">
          <fm-form-item label="申请科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.applyOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="维修资产">
            <div class="assets-list">
              <div class="assets-item" v-for="detail in formData.detailList.filter(v => !v.isDel)" :key="detail.id">
                <div v-if="detail.assets">
                  {{detail.assets.code}}
                </div>
                <div v-if="detail.assets">
                  {{detail.assets.name}}
                </div>
                <div v-if="!detail.assets">
                  {{detail.assetsName}}
                </div>
                <div v-if="!detail.assets">
                  {{detail.jz + '元'}}
                </div>
              </div>
            </div>
          </fm-form-item>
          <fm-form-item label="关联预算">
            <fm-select absolute filterable :clearable="true" v-model="formData.budgetDetailId">
              <fm-option v-for="item in budgetDetailList" :key="item.id" :value="item.id" :label="item.code + ' ' + item.goodsName"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="预估维修金额">
            <fm-input-new v-model="formData.repairJe"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="维修厂家">
            <fm-input-new v-model="formData.repairCmp"></fm-input-new>
          </fm-form-item>
          <!-- <fm-form-item label="维修内容">
            <fm-input-new type="textarea" v-model="formData.content"></fm-input-new>
          </fm-form-item> -->
          <fm-form-item label="申请理由">
            <fm-input-new type="textarea" v-model="formData.reason"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="附件">
            <file-manage
              :file-data-ids="fileIds"
              @addFile="addFile"
              sortable
              @updateSort="ids => fileIds = ids"
              :funs="{get: true, upload: true, del: true}"
              @delFile="delFile"/>
          </fm-form-item>
        </fm-form>
        <div style="text-align: center;padding-top: 20px;" v-if="formData.id">维修申请已新增，但未提交至职能科室，{{canSubmit ? '请点击提交按钮进行提交，或在维修申请中进行处理。' : '无该维修申请修改及提交权限'}}</div>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" v-if="canSubmit" @click="formSubmit">提交</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
    </div>
    <submit-form @submit="submit" ref="dealBlockSubmitForm" update-keys="busOrgId"></submit-form>
  </fm-modal>
</template>

<script>
import {
  assetsRepairApplyRequest
} from '../../../api'

import FileManage from '@/components/base/FileManage'
import submitForm from './submitForm'

export default {
  components: {
    FileManage,
    submitForm
  },
  props: {
    data: { type: Object, defualt: () => null },
    byAssets: { type: Boolean, defualt: false},
    orgList: { type: Array, defualt: () => [] },
    assetsList: { type: Array, defualt: () => [] },
    budgetDetailList: { type: Array, defualt: () => [] }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        let data = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          isAssetsId: '是'
        }
        data.isAssetsId = data.isAssetsId || (!data.id || data.assetsId ? '是' : '否')
        this.formData = data
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      searchText: '',
      step: 0,
      modal: false,
      loading: false,
      fileIds: [],
      canSubmit: true,
      canEdit: true,
    }
  },
  methods: {
    async submit (p) {
      let parm = {
        statusActionKey: 'draw_switch_wait_1',
        busOrgId: p.busOrgId
      }
      await assetsRepairApplyRequest.switchStatus(this.formData.id, parm)
      this.$notify({
        title: '系统提示',
        message: '维修申请已提交',
        type: 'success'
      })
      this.modal = false
    },
    async addFile (data) {
      if (!this.fileIds.includes(data.id)) {
        this.fileIds.push(data.id)
      }
    },
    async delFile (data) {
      const index = this.fileIds.findIndex(v => data.id === v)
      if (index > -1) {
        this.fileIds.splice(index, 1)
      }
    },
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (!this.formData.applyOrgId) {
        if (this.orgList.length === 1) {
          this.formData.applyOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择申请科室',
            type: 'info'
          })
          return
        }
      }
      if (isNaN(this.formData.repairJe)) {
        this.$notify({
          title: '系统提示',
          message: '预估维修金额请输入数值',
          type: 'info'
        })
        return
      }
      if (!this.formData.budgetDetailId) {
        this.$notify({
          title: '系统提示',
          message: '请选择预算',
          type: 'info'
        })
        return
      }
      if (this.formData.repairJe != null && typeof this.formData.repairJe === 'string') {
        this.formData.repairJe = this.formData.repairJe.replaceAll(' ', '')
        if (this.formData.repairJe === '') {
          this.formData.repairJe = null
        }
      }
      this.formData.type = this.formData.jz && this.formData.jz > 2000 ? 'norm' : 'mini'
      this.loading = true
      let data = this.formData
      let isAdd = false
      if (data.id) {
        await assetsRepairApplyRequest.update(data.id, data)
      } else {
        let rData = await assetsRepairApplyRequest.add(data)
        isAdd = true
        this.formData.id = rData.id
      }
      await assetsRepairApplyRequest.files(this.formData.id, {
        fileIds: this.fileIds.join(',')
      })
      if (isAdd) {
        this.$notify({
          title: '系统提示',
          message: '维修申请已新增',
          type: 'success'
        })
      }
      let authData = await assetsRepairApplyRequest.getByAuth({
        id: this.formData.id
      })
      if (authData && authData.length > 0 && authData[0].actions && authData[0].actions.find(v => v.key === 'draw_switch_wait_1')) {
        this.$refs.dealBlockSubmitForm.modal = true
      } else {
        this.canSubmit = false
        this.$notify({
          title: '系统提示',
          message: '维修申请已新增，但无该维修申请提交权限，无法提交。',
          type: 'info'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 100%;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.assets-list {
  display: flex;
  flex-wrap: wrap;
  .assets-item {
    position: relative;
    min-width: 150px;
    display: flex;
    min-height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 0 20px;
    background: rgba(0,0, 0, 0.1);
    border-radius: 5px;
    .close {
      position: absolute;
      top: 0;
      right: 10px;
      color: #999;
    }
  }
}
</style>