<template>
  <fm-modal title="提交" v-model="modal" width="600px" v-if="modal">
    <fm-form label-align="left">
      <fm-form-item label="职能科室" v-if="updateKeys && updateKeys.split(',').includes('busOrgId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
          <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="科室负责人" v-if="updateKeys && updateKeys.split(',').includes('orgChargeUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.orgChargeUserId">
          <fm-option v-for="item in orgChargeUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="分管领导" v-if="updateKeys && updateKeys.split(',').includes('leadUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.leadUserId">
          <fm-option v-for="item in leadUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="分管资产领导" v-if="updateKeys && updateKeys.split(',').includes('assetsLeadUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.assetsLeadUserId">
          <fm-option v-for="item in assetsLeadUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="残余价值" v-if="updateKeys && updateKeys.split(',').includes('jz')">
        <fm-input-new v-model="formData.jz"></fm-input-new>
      </fm-form-item>
    </fm-form>
    <div class="model-btn">
      <fm-btn style="margin-right: 30px;" @click="formSubmit">提交</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
export default {
  props: {
    updateKeys: { type: String, defualt: null }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    scrapConfig () {
      let scrapConfig = this.$store.getters.sysConfig.scrap_config || {}
      return {
        busOrgIdList: scrapConfig.busOrgIdList || [],
        assetsLeadUserIdList: scrapConfig.assetsLeadUserIdList || [],
        leadUserIdList: scrapConfig.leadUserIdList || [],
        orgChargeUserIdList: scrapConfig.orgChargeUserIdList || []
      }
    },
    orgList () {
      return this.$store.getters.orgList.filter(v => this.scrapConfig.busOrgIdList.length === 0 || this.scrapConfig.busOrgIdList.includes(v.data.id))
    },
    assetsLeadUserList () {
      return this.$store.getters.userList.filter(v => this.scrapConfig.assetsLeadUserIdList.length === 0 || this.scrapConfig.assetsLeadUserIdList.includes(v.data.id))
    },
    leadUserList () {
      return this.$store.getters.userList.filter(v => this.scrapConfig.leadUserIdList.length === 0 || this.scrapConfig.leadUserIdList.includes(v.data.id))
    },
    orgChargeUserList () {
      return this.$store.getters.userList.filter(v => this.scrapConfig.orgChargeUserIdList.length === 0 || this.scrapConfig.orgChargeUserIdList.includes(v.data.id))
    }
  },
  methods: {
    async formSubmit () {
      let pass = true
      this.updateKeys.split(',').forEach(v => {
        if (!this.formData[v]) {
          pass = false
        } else if (v === 'jz' && isNaN(this.formData[v])) {
          pass = false
        }
      })
      if (!pass) {
        this.$notify({
          title: '系统提示',
          message: '请检查表单',
          type: 'info'
        })
      } else {
        this.$emit('submit', this.formData)
        this.modal = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>